/*globals $:false */
$(function() {
    'use strict';

    var subscriptionInformationHandler,
        toolName,
        countryField,
        authorTypeField,
        additionalSubscriptionDataGetter;

    // Widget: Subscription for new Tolbox tools & digest
    subscriptionInformationHandler = function (subscriptionInformation) {};
    additionalSubscriptionDataGetter = function () { return {}; };
    activateNotificationserverWidget('toolbox', 'Toolbox', 'new tools', subscriptionInformationHandler, additionalSubscriptionDataGetter);

    // Widget: Subscription for new Planningboard activities & digest
    subscriptionInformationHandler = function (subscriptionInformation) {};
    additionalSubscriptionDataGetter = function () { return {}; };
    activateNotificationserverWidget('tca-planningboard', 'TcaPb', 'new activities in the Planningboard', subscriptionInformationHandler, additionalSubscriptionDataGetter);

    // Widget: Subscription for new ETC training offers & digest
    toolName = 'etc';
    countryField = $('.fx-etc-subscription-widget select[name="country"]');
    authorTypeField = $('.fx-etc-subscription-widget select[name="authortype"]');
    additionalSubscriptionDataGetter = function () {
      return { countryId: countryField.val(), authorType: authorTypeField.val() };
    };
    subscriptionInformationHandler = function (subscriptionInformationAsString) {
      var subscriptionInformation = JSON.parse(subscriptionInformationAsString);
      countryField.val(subscriptionInformation.countryId).removeAttr('disabled');
      authorTypeField.val(subscriptionInformation.authorType).removeAttr('disabled');
    };
    activateNotificationserverWidget(toolName, 'TrainingCalendar', 'new training offers in the Europan Training Calendar', subscriptionInformationHandler, additionalSubscriptionDataGetter);
    countryField.change(function () {
      $('.fx-' + toolName + '-subscription-checkbox').trigger("change");
    });
    authorTypeField.change(function () {
      $('.fx-' + toolName + '-subscription-checkbox').trigger("change");
    });

    // Function to activate the JS for a NotificationService subscription widget
    function activateNotificationserverWidget(toolName, toolIdAtNotificationService, runningTextDescription, subscriptionInformationHandler, additionalSubscriptionDataGetter) {
      $('.fx-' + toolName + '-subscription-widget').each(function (index, element) {
        var baseUrl = $(element).data('baseurl');

        // Enable and prefill checkbox for new activities subscriptions
        $.ajax({
          url: baseUrl + 'subscription/' + toolIdAtNotificationService,
          cache: false,
          xhrFields: {
            withCredentials: true
          },
          type: "GET",
          success: function(subscriptionInformation) {
            subscriptionInformationHandler(subscriptionInformation);

            // '0' means 'not subscribed' in all tools
            if (subscriptionInformation !== '0') {
              $('.fx-' + toolName + '-subscription-checkbox', element).attr('checked', 'checked');
            }

            $('.fx-' + toolName + '-subscription-checkbox', element).removeAttr('disabled');
          }
        });

        // Listener for subscription checkbox
        $('.fx-' + toolName + '-subscription-checkbox', element).change(function () {
          var data = $.extend(
              {action: ($(this).is(':checked') ? 'subscribe' : 'unsubscribe')},
              additionalSubscriptionDataGetter()
          );

          $.ajax({
            url: baseUrl + 'subscription/' + toolIdAtNotificationService,
            cache: false,
            xhrFields: {
              withCredentials: true
            },
            type: "POST",
            data: data,
            context: this,
            success: function() {
              setFlashMessage('You are now ' + ($(this).is(':checked') ? 'subscribed to ' : 'unsubscribed from ') + runningTextDescription + '.');
            }
          });
        });

        // Enable and prefill select box for digest options
        $.ajax({
          url: baseUrl + 'digest/' + toolIdAtNotificationService,
          cache: false,
          xhrFields: {
            withCredentials: true
          },
          type: "GET",
          success: function(data) {
            if (data === 'immediately' || data === 'daily' || data === 'weekly') {
              $('.fx-' + toolName + '-subscription-digest-select', element).val(data);
              $('.fx-' + toolName + '-subscription-digest-select', element).removeAttr('disabled');
            }
          }
        });

        // Listener for digest select box
        $('.fx-' + toolName + '-subscription-digest-select', element).change(function () {
          $.ajax({
            url: baseUrl + 'digest/' + toolIdAtNotificationService,
            cache: false,
            xhrFields: {
              withCredentials: true
            },
            type: "POST",
            data: {
              digestOption: $(this).val()
            },
            context: this,
            success: function() {
              setFlashMessage('You will now receive email notifications for ' + runningTextDescription + ' ' + ($(this).val()) + '.');
            }
          });
        });

        function setFlashMessage(message) {
          // unfortunately, reuse of a backend generated flash message is not possible, so there's a little bit of duplicate markup
          $('.fx-content.running-text').html('<div class="flash success">' + message + '</div>');
        }
      });
    }
});
