/*globals $:false */
'use strict';

$(function() {
  $('.js-salto-widget').each(function(index, element) {
    $.ajax({
      url: $(element).data('url'),
      cache: false,
      xhrFields: {
        withCredentials: true
      },
      type: 'GET',
      success: function(data) {
        var number = data[$(element).data('number-property')];

        if (number > 0) {
          var text = $(element).data('text')
            .replace('{number}', number)
            .replace('{number2}', data[$(element).data('number-property-2')]);

          $(element)
            .addClass('flash notice')
            .html(
              '<h2>' + $(element).data('headline') + '</h2>' +
              '<p>' + text + '</p>' +
              '<p><a href="' + $(element).data('link-url') + '" class="button round-button">' + $(element).data('link-label') + '</a></p>'
            ).show();
        }
      }
    });
  })
});
